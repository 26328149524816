import { useState } from "react";
import { twMerge } from "tailwind-merge";

export const ToggleDevMode = () => {
  const [isDevMode, setIsDevMode] = useState(() => {
    return new URLSearchParams(location.search).get("devMode") === "true";
  });

  // This statement prevents this entire module from being bundled in our production builds
  if (!(import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV)) {
    return;
  }

  return (
    <div className="fixed bottom-2 left-2">
      <button
        className={twMerge(
          "rounded-xl p-2",
          "font-mono text-xs font-bold text-neutral-200/80",
          "bg-purple-900 dark:bg-purple-900/40",
        )}
        onClick={() => {
          if (isDevMode) {
            setIsDevMode(false);
            location.href = location.origin;
          } else {
            setIsDevMode(true);
            location.search = "?devMode=true";
          }
        }}
      >
        → {isDevMode ? "Use Integration mode" : "Use dev mode"}
      </button>
    </div>
  );
};
